import React, { useEffect } from "react";
import OurOffering from "../../components/ourOffering/component";
import { useApp } from "../../hooks/AppHooks";

const CuversePage: React.FC = () => {
    const { homeEnglishData } = useApp();
    const bannerImage = homeEnglishData?.ourOffering?.videosection;
    const videourl = homeEnglishData?.ourOffering;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    return (
        <>
            <OurOffering  videourl={videourl}/>
        </>
    )
}
export default CuversePage;
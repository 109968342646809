import React from "react";

const FaqPage = () => {
    return (
        <>
            <div className="faq-page coming-soon">
                <h1>Coming Soon</h1>
            </div>
        </>
    )
}
export default FaqPage;
import React, { useEffect } from "react";
import { useApp } from "../hooks/AppHooks";
import BannerCarousel from "../components/bannerCaraousel/component";
import AboutUsComponent from "../components/aboutUs/component";
import { Container } from "react-bootstrap";

const AboutPage: React.FC = () => {
    const { homeEnglishData } = useApp();
    const bannerImage = homeEnglishData?.about?.bannerSection;
    const aboutData = homeEnglishData?.about?.aboutus
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])
    return (
        <>
            <div className="deveoper-page">
                <BannerCarousel bannerImage={bannerImage} />
                <Container>
                    <AboutUsComponent aboutData={aboutData} />
                </Container>
            </div>
        </>
    )
}
export default AboutPage;
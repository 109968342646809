import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import ReactPannellum from "react-pannellum";

// Define a type for the media items
interface MediaItem {
    src: string;
}

const Render360Tabs: React.FC = () => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [selectedImages, setSelectedImages] = useState<MediaItem[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [moveHide, setMoveHide] = useState<boolean>(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Create combined media array
    const interiorMedia: MediaItem[] = Array.from({ length: 13 }, (_, i) => ({
        src: `/assets/images/renders/render_360/interior/${i + 1}.jpg`,
    }));

    const exteriorMedia: MediaItem[] = Array.from({ length: 6 }, (_, i) => ({
        src: `/assets/images/renders/render_360/exterior/${i + 1}.jpg`,
    }));

    // Combine both interior and exterior media
    const combinedMedia: MediaItem[] = [...interiorMedia, ...exteriorMedia];

    const handleThumbnailClick = (index: number) => {
        setSelectedIndex(index);
        setOpenModal(true);
        handleShow();
        setTimeout(() => {
            setMoveHide(true);
        }, 1000);
    };

    useEffect(() => {
        setSelectedImages(combinedMedia);
    }, [combinedMedia]);

    return (
        <div className="render-page">
            <Row>
                <div className="pre-design-content render-tabs-items">
                    {/* Combined Renders */}
                    <div className="renders">
                        <Row>
                            {
                                selectedImages.map((row: MediaItem, index: number) => (
                                    <Col md={3} key={index}>
                                        <div style={{ cursor: "pointer", marginBottom: "10px" }}>
                                            <div
                                                className="renderImagesBox"
                                                style={{ cursor: "pointer", width: '100%', margin: 'auto', position: 'relative' }}
                                                onClick={() => handleThumbnailClick(index)}
                                            >
                                                <img className="img-fluid" src={row.src} alt={`Render ${index + 1}`} />
                                                <div className="preview-images-tabs"><small>Preview</small></div>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>

                    {/* Modal for selected render */}
                    {openModal && (
                        <Modal show={show} onHide={handleClose} className="render-modal-section video-modal">
                            <ReactPannellum
                                id="1"
                                width={'100%'}
                                height={'100%'}
                                sceneId="firstScene"
                                imageSource={selectedImages[selectedIndex].src}
                                config={{
                                    autoLoad: true,
                                    mouseZoom: false,
                                    disableZoom: true,
                                    hfov: 130,
                                }}
                            />
                            <button onClick={handleClose} className="render-closeBtn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                                </svg>
                            </button>
                            {
                                moveHide && (
                                    <div className="move-direction" onClick={() => { setMoveHide(false); }}>
                                        <img src="/assets/images/hand.gif" alt="Move direction" />
                                    </div>
                                )
                            }
                        </Modal>
                    )}
                </div>
            </Row>
        </div>
    );
};

export default Render360Tabs;

import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useApp } from '../../../hooks/AppHooks';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Marquee from 'react-fast-marquee';

const OurProcess: React.FC = () => {
    const { homeEnglishData } = useApp();
    const data = homeEnglishData?.home;
    const [progress, setProgress] = useState<number[]>([]);
    const [activeSlide, setActiveSlide] = useState('s2'); // Default to the second slide
    const [isInView, setIsInView] = useState(false); // Flag to check if the section is in view
    const projectDataLoopRef = useRef<HTMLDivElement>(null);
    const observerRef = useRef<IntersectionObserver | null>(null);

    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    };

    useEffect(() => {
        if (data?.projectlive) {
            setProgress(new Array(data.projectlive.length).fill(0));
        }
    }, [data?.projectlive]);

    useEffect(() => {
        const observerCallback = (entries: IntersectionObserverEntry[]) => {
            if (entries[0].isIntersecting) {
                setIsInView(true);
                observerRef.current?.disconnect();
            }
        };

        if (projectDataLoopRef.current) {
            observerRef.current = new IntersectionObserver(observerCallback, { threshold: 0.1 });
            observerRef.current.observe(projectDataLoopRef.current);
        }

        return () => observerRef.current?.disconnect();
    }, []);

    useEffect(() => {
        if (isInView && data?.projectlive) {
            const totalDuration = 5000; // Total duration for the animation in ms
            const startTime = performance.now();

            const animateProgress = (time: number) => {
                const elapsed = time - startTime;
                const progressPercentage = Math.min(elapsed / totalDuration, 1);

                setProgress(prevProgress => prevProgress.map((value, index) => {
                    const targetNumber = parseInt(data.projectlive[index]?.number) || 0;
                    return Math.round(targetNumber * progressPercentage);
                }));

                if (elapsed < totalDuration) {
                    requestAnimationFrame(animateProgress);
                }
            };

            requestAnimationFrame(animateProgress);
        }
    }, [isInView, data?.projectlive]);

    useEffect(() => {
        const radios = document.querySelectorAll<HTMLInputElement>('input[name="slider"]');
        radios.forEach((radio) => {
            radio.addEventListener('change', updateCurrentSlide);
        });

        return () => {
            radios.forEach((radio) => {
                radio.removeEventListener('change', updateCurrentSlide);
            });
        };
    }, []);

    const updateCurrentSlide = () => {
        const activeSlideElement = document.querySelector<HTMLInputElement>('input[name="slider"]:checked');
        const slideId = activeSlideElement ? activeSlideElement.id : 's2'; // Default to the second slide if none is checked
        setActiveSlide(slideId);
    };


    return (
        <div className="ourProcess">
            <div className='our-process-section'>
                <Container>
                    <Row className='mb-5 pb-3'>
                        <Col xs={12} className="ourProcessTitle">
                            <h3>{data?.ourProcess?.title}</h3>
                            <p>{data?.ourProcess?.sectionDetail}</p>
                        </Col>
                    </Row>
                    <Row>
                        {data?.ourProcess?.items?.map((item: any, index: number) => (
                            <Col key={index} xs={12} md={6} lg={3}>
                                <Card className="OurProcessBox">
                                    <Card.Body>
                                        <strong>{item?.number}</strong>
                                        {/* <strong>{item?.subtitle}</strong> */}
                                        <h4>{item?.title}</h4>
                                        <h6>{item?.subtitle}</h6>
                                        <p>{item.desription}</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
                <div className="wavecontainer">
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
            </div>
            <div className="trustSlider">
                <Container>
                    <Row className="trustHeading">
                        <Col xs={12}>
                            <h3 style={{ color: "#f2f5f7" }}>{data?.ourProcess?.ourtTrust?.title}</h3>
                            <h3 style={{ color: "#f2f5f7" }}>{data?.ourProcess?.ourtTrust?.subtitle}</h3>
                        </Col>
                    </Row>
                    {
                        window.innerWidth > 1212
                            ?
                            <section id="slider">
                                <input type="radio" name="slider" id="s1" onChange={updateCurrentSlide} />
                                <input type="radio" name="slider" id="s2" defaultChecked onChange={updateCurrentSlide} />
                                <input type="radio" name="slider" id="s3" onChange={updateCurrentSlide} />
                                <input type="radio" name="slider" id="s4" onChange={updateCurrentSlide} />
                                <input type="radio" name="slider" id="s5" onChange={updateCurrentSlide} />

                                <label htmlFor="s1" id="slide1" className={activeSlide === 's1' ? 'active' : ''}>
                                    <div className="slide-content">
                                        <div className='slide-image'>
                                            <img src='/assets/images/testi/skyland.png' className='img-fluid' />
                                        </div>
                                        <div className='slider-content-box' style={{ textAlign: 'start', padding: '47px', marginLeft: '100px' }}>
                                            <h2>Skyland Istanbul</h2>
                                            <p>Cubedots has been fantastic solutions partner for us here at Skyland. Their 3D portal allows us to showcase our project to the world by giving us an online sales office.</p>
                                        </div>
                                    </div>
                                </label>

                                <label htmlFor="s2" id="slide2" className={activeSlide === 's2' ? 'active' : ''}>
                                    <div className="slide-content">
                                        <div className='slide-image'>
                                            <img src='/assets/images/testi/artas.png' className='img-fluid' />
                                        </div>
                                        <div className='slider-content-box' style={{ textAlign: 'start', padding: '47px', marginLeft: '100px' }}>
                                            <h2>ARTAS – YAMANEVLER</h2>
                                            <p>Cubedots approach to sales has been refreshing and forward thinking, by utilizing their technology we were able to sell our project purely online to clients.</p>
                                        </div>
                                    </div>
                                </label>

                                <label htmlFor="s3" id="slide3" className={activeSlide === 's3' ? 'active' : ''}>
                                    <div className="slide-content">
                                        <div className='slide-image'>
                                            <img src='/assets/images/testi/tema.png' className='img-fluid' />
                                        </div>
                                        <div className='slider-content-box' style={{ textAlign: 'start', padding: '47px', marginLeft: '100px' }}>
                                            <h2>Tema Istanbul 2</h2>
                                            <small>M. Aydın Ayçenk, Tema Istanbul General Manager</small>
                                            <p>Cubedots and their team share the same core values as us and especially when it comes to customer service.</p>
                                        </div>
                                    </div>
                                </label>

                                <label htmlFor="s4" id="slide4" className={activeSlide === 's4' ? 'active' : ''}>
                                    <div className="slide-content">
                                        <div className='slide-image'>
                                            <img src='/assets/images/testi/qataru-diar.png' className='img-fluid' />
                                        </div>
                                        <div className='slider-content-box' style={{ textAlign: 'start', padding: '47px', marginLeft: '100px' }}>
                                            <h2>Khalid El Amin
                                                Qatari Diar</h2>
                                            <p>It was a pleasure working with the CU Render Team. I'd like to commend them on their professionalism, efficiency and most importantly their execution. Their understanding of creating collaterals from little to no reference made the whole process easy and they were able to deliver high quality from limited resources. Looking forward to growing together.</p>
                                        </div>
                                    </div>
                                </label>

                                <label htmlFor="s5" id="slide5" className={activeSlide === 's5' ? 'active' : ''}>
                                    <div className="slide-content">
                                        <div className='slide-image'>
                                            <img src='/assets/images/testi/lux.png' className='img-fluid' />
                                        </div>
                                        <div className='slider-content-box' style={{ textAlign: 'start', padding: '47px', marginLeft: '100px' }}>
                                            <h2>Lux La Baraquette, Properties & Co. </h2>
                                            <p>Cubedots has been a phenomenal partner and very responsive to our needs.
                                                Jean-François Garneau </p>
                                        </div>
                                    </div>
                                </label>
                            </section>

                            :
                            <Slider {...settings}>
                                {data?.ourProcess?.ourtTrust?.description.map((item: any, index: number) => (
                                    <div key={index}>
                                        <Row className="justify-content-center">
                                            <Col md={10}>
                                                <Card className="turstSliderContent">
                                                    <Card.Body>
                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Col md={10} xs={11}>
                                                                <div style={{
                                                                    textAlign: "center"
                                                                }}>
                                                                    <img
                                                                        src={item?.title}
                                                                        alt="Thumbnail"
                                                                        width={40}
                                                                    />
                                                                    <h5>{item?.agentName}</h5>
                                                                    <small>{item?.post}</small>
                                                                    <p>{item?.decription}</p>
                                                                    <div className="d-flex align-items-center  mt-3">
                                                                        <div className="me-2">
                                                                        </div>
                                                                        <div className="ml-2">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </Slider>
                    }

                </Container >
            </div >
            <div style={{ marginTop: "50px" }} ref={projectDataLoopRef}>
                <h3 className="main-page-heading" style={{ textAlign: "center", marginBottom: "40px" }}>What We Have Achieved </h3>
                <div className='project-data-loop'>
                    <Row style={{ justifyContent: 'center' }}>
                        {data?.projectlive?.map((item: any, index: number) => (
                            <Col md={"auto"} style={{ flexGrow: "1" }} xs={4} key={index} className="marquee-item">
                                <p>{item?.title}</p>
                                <h3>{progress[index]}+</h3>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            <Container>
                <h3 className="main-page-heading" style={{ textAlign: "center", marginBottom: "40px" }}>Our Powerful Partnership</h3>
            </Container>
            <Marquee style={{ margin: '24px 0' }}>
                {data?.ourAssuranceMarquee?.map((item: any, index: number) => (
                    <Card key={index} className="marquee-item">
                        <Card.Body>
                            <img src={item.img} className='img-fluid' />
                        </Card.Body>
                    </Card>
                ))}
            </Marquee>
        </div >
    );
};

export default OurProcess;

import React from "react";
import { useApp } from "../../hooks/AppHooks";
import BannerCarousel from "../bannerCaraousel/component";
import { Col, Container, Row } from "react-bootstrap";
import "./style.scss"

const ProductComponent: React.FC = () => {
    const { homeEnglishData } = useApp();
    const bannerImage = homeEnglishData?.product?.bannerSection;
    return (
        <>
            <div className="product-section">
                <BannerCarousel bannerImage={bannerImage} />
                <div className="product-content-section">
                    <Container>
                        <h3  className="main-page-heading" style={{ textAlign: "center" }}><span>Product</span></h3>
                        <div className="tailored-product">
                            <Row style={{ justifyContent: "space-between" }}>
                                <Col md={5}>
                                    <h3>Tailored Navigation for Ultimate Convenience</h3>
                                    <img src="/assets/images/01.jpg" className="img-fluid" />
                                </Col>
                                <Col md={6}>
                                    <p>With our user-friendly navigation filter, users can effortlessly sift through property options based on specific criteria.From bedroom types and floor preferences to pricing, availability, and area sizes, users can fine-tune their search with ease.From bedroom types and floor preferences to pricing, availability, and area sizes, users can fine-tune their search with ease.From bedroom types and floor preferences to pricing, availability, and area sizes, users can fine-tune their search with ease.</p>
                                    <p>
                                        This personalized approach enhances the user experience and streamlines decision-making. Best of all, our cross-platform support ensures this feature's accessibility across devices, ensuring that property exploration remains a smooth and hassle-free journey.
                                        Leveraging browser-based technology, users can now seamlessly immerse themselves in rich interactivity, gaining insights into precise locations through interactive videos and visualizing their prospective apartments. The best part is all of this can be achieved without the hassle of downloading extensive assets or applications.This personalized approach enhances the user experience and streamlines decision-making. Best of all, our cross-platform support ensures this feature's accessibility across devices, ensuring that property exploration remains a smooth and hassle-free journey.
                                        Leveraging browser-based technology, users can now seamlessly immerse themselves in rich interactivity.
                                    </p>
                                </Col>
                            </Row>
                            <p style={{ marginTop: "20px" }}>Sell from ANYWHERE at ANYTIME.
                                Realty Verse is our industry-leading 3D sales system and it is here to supercharge your sales! Expand your sales internationally, and broaden your marketing efforts now into new markets. We use the latest in computer power and high-quality design to deliver a sales experience for you and your clients like never before. The Realty Verse is here to make your life easier, clearly showcase our projects from anywhere in the world with your clients, view our live inventory, and even make a reservation.This truly is the future of real estate sales.</p>
                        </div>
                        <div className="product-second-section">
                            <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                <Col md={5}>
                                    <img src="/assets/images/01.jpg" className="img-fluid" />
                                </Col>
                                <Col md={6}>
                                    <h3>Lorem ipsum dolor sit amet consectetur. Suscipit.</h3>
                                    <p>This personalized approach enhances the user experience and streamlines decision-making. Best of all, our cross-platform support ensures this feature's accessibility across devices, ensuring that property exploration remains a smooth and hassle-free journey.</p>
                                </Col>
                            </Row>
                            <p
                                style={{ marginTop: "20px" }}
                            >
                                Leveraging browser-based technology, users can now seamlessly immerse themselves in rich interactivity, gaining insights into precise locations through interactive videos and visualizing their prospective apartments. The best part is all of this can be achieved without the hassle of downloading extensive assets or applications.This personalized approach enhances the user experience and streamlines decision-making. Best of all, our cross-platform support ensures this feature's accessibility across devices, ensuring that property exploration remains a smooth and hassle-free journey.
                                Leveraging browser-based technology, users can now seamlessly immerse themselves in rich interactivity.
                            </p>
                        </div>
                        <div className="product-third-section">
                            <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                                <Col md={6}>
                                    <h3>Lorem ipsum dolor sit amet consectetur. Suscipit.</h3>
                                    <p>This personalized approach enhances the user experience and streamlines decision-making. Best of all, our cross-platform support ensures this feature's accessibility across devices, ensuring that property exploration remains a smooth and hassle-free journey.</p>
                                </Col>
                                <Col md={5}>
                                    <img src="/assets/images/01.jpg" className="img-fluid" />
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <h3 style={{ textAlign: 'center' }}>Portfolio</h3>
                            <div className="port-folio">
                                <Row style={{ justifyContent: "space-between", marginBottom: "30px" }}>
                                    <Col md={6}>
                                        <img src="/assets/images/render.png" className="img-fluid"
                                            style={{ width: "100%", height: "200px", borderRadius: "20px" }}
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <h3>AL Safa</h3>
                                        <p>This personalized approach enhances the user experience and streamlines decision-making. </p>
                                        <button>360<sup>0</sup> Renders</button>
                                    </Col>
                                </Row>
                                <Row style={{ justifyContent: "space-between", marginBottom: "30px" }}>
                                    <Col md={6}>
                                        <img src="/assets/images/thre-360.png" className="img-fluid"
                                            style={{ width: "100%", height: "200px", borderRadius: "20px" }}
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <h3>AL Safa</h3>
                                        <p>This personalized approach enhances the user experience and streamlines decision-making. </p>
                                        <button>360<sup>0</sup> Renders</button>
                                    </Col>
                                </Row>
                                <Row style={{ justifyContent: "space-between", marginBottom: "30px" }}>
                                    <Col md={6}>
                                        <img src="/assets/images/thre-360.png" className="img-fluid"
                                            style={{ width: "100%", height: "200px", borderRadius: "20px" }}
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <h3>AL Safa</h3>
                                        <p>This personalized approach enhances the user experience and streamlines decision-making. </p>
                                        <button>360<sup>0</sup> Renders</button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}
export default ProductComponent;
import React from "react";
import { Col, Row, Nav, Tab } from "react-bootstrap";
import "../Developers.scss";


interface TabSectionProps {
    developerData: any; // You can replace `any` with the specific type if known
}

const TabSection: React.FC<TabSectionProps> = ({ developerData }) => {
    return (
        <>
            <div className="tab-section">
                <h3 className="main-page-heading" style={{ textAlign: "center" }}><span>Developers</span></h3>
                <div className="tab-heading">
                    <Row>
                        <Col sm={3}>
                            <h3>{developerData?.hedingcontent[0]?.title}</h3>
                            <p>{developerData?.hedingcontent[0]?.decription}</p>
                        </Col>
                        <Col sm={3}>
                            <h3>{developerData?.hedingcontent[1]?.title}</h3>
                            <p>{developerData?.hedingcontent[1]?.decription}</p>
                        </Col>
                        <Col sm={6}>
                            <h3>{developerData?.hedingcontent[2]?.title}</h3>
                        </Col>

                    </Row>
                </div>
                <div className="tab-content">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    {developerData?.tabsection?.map((item: any, index: number) => (
                                        <Nav.Item key={index}>
                                            <Nav.Link eventKey={item?.tabvalue}>
                                                {item.title}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    {developerData?.tabsection?.map((item: any, index: number) => (
                                        <Tab.Pane eventKey={item?.tabvalue}>
                                            {item.description}
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </>
    )
}
export default TabSection;
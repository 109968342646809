import React, { useEffect, useRef } from "react";
import VideoComponent from "../components/home/videoSection/component";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useApp } from "../hooks/AppHooks";
import AssurancePage from "../components/home/Assurance/component";
import OurProcess from "../components/home/ourprocess/component";
import "../components/home/home.scss"

const HomePage: React.FC = () => {
    const { homeEnglishData } = useApp();
    const dataItem = homeEnglishData?.home;
    const videourl = homeEnglishData?.home;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    return (
        <>
            <div className="home-page">
                <VideoComponent videourl={videourl} />
                <AssurancePage />
                <OurProcess />
            </div>
        </>
    )
}
export default HomePage;
import React from "react";
import Slider from "react-slick";
import "../Developers.scss";
import { Col, Row } from "react-bootstrap";

interface ProjectListProps {
    developerData: any; // You can replace `any` with the specific type if known
}

const ProjectList: React.FC<ProjectListProps> = ({ developerData }) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                }
            }

        ]
    };

    return (
        <>
            <div className="project-list">
                <h3 >{developerData?.projectlist?.heading}</h3>
                <div className="slider-container">
                    <Slider {...settings}>
                        {
                            developerData?.projectlist?.projectmenu?.map((item: any, index: number) => {
                                return (
                                    <>
                                        <Row key={index} style={{ justifyContent: "center" }}>
                                            <Col md={11}>
                                                <div className="project-list-items">
                                                    <img src={item.image} className="img-fluid" />
                                                    <div className="project-list-content">
                                                        <h4>{item.title}</h4>
                                                        <small>{item.location}</small>
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </>
    )
}
export default ProjectList;
import React, { useEffect } from "react";
import ProductComponent from "../components/product/component";

const ProductPage: React.FC = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])
    return (
        <>
            <ProductComponent />
        </>
    )
}
export default ProductPage;
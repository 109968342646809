import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";

// Define the type for media items
interface MediaItem {
    src: string;
    thumbnail: string;
}

// Define the type for mediaData
interface MediaData {
    interior: MediaItem[];
    exterior: MediaItem[];
}

// Define the type for openModal state
interface OpenModalState {
    open: boolean;
    type: keyof MediaData; // 'interior' | 'exterior'
    index: number;
}

const CinematicVideos = () => {
    // Use strict typing for openModal state
    const [openModal, setOpenModal] = useState<OpenModalState>({ open: false, type: 'interior', index: 0 });
    const handleClose = () => setOpenModal({ open: false, type: 'interior', index: 0 });

    // Explicitly type the mediaData object
    const mediaData: MediaData = {
        interior: [
            { src: "https://youtu.be/cwhzCOkuctA", thumbnail: "/assets/images/renders/cinematic/interior/alya.jpg" },
            { src: "https://youtu.be/uI1vaWlyqZU", thumbnail: "/assets/images/renders/cinematic/interior/green_city.jpg" },
            { src: "https://youtu.be/IqiuH_l98uA", thumbnail: "/assets/images/renders/cinematic/interior/green_plaza.jpg" }
        ],
        exterior: [
            { src: "https://youtu.be/mHt70pdadZk", thumbnail: "/assets/images/renders/cinematic/exterior/green_city.jpg" },
            { src: "https://youtu.be/YGMJVVe3Ydw", thumbnail: "/assets/images/renders/cinematic/exterior/valory.jpg" },
            { src: "https://youtu.be/4mW_agb-5jc", thumbnail: "/assets/images/renders/cinematic/exterior/yaman.jpg" }
        ]
    };

    const settings = { dots: true, infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1 };

    const renderSection = (type: keyof MediaData, title: string, description: string, items: Array<MediaItem>, reverse: boolean) => (
        <Row>
            {reverse ? (
                <>
                    <Col md={6}>
                        <Slider {...settings}>
                            {items.map((item, index) => (
                                <div key={index} className="cinematic-videos-images" style={{ cursor: "pointer", width: '100%', margin: 'auto', position: 'relative' }} onClick={() => setOpenModal({ open: true, type, index })}>
                                    <img src={item.thumbnail} alt={`${type} thumbnail`} className="img-fluid" />
                                    <div className="preview-images">
                                        <Link to="/renders?tab=cinematic"><button className="preview-btn">See More</button></Link>
                                        <button className="preview-btn" onClick={() => setOpenModal({ open: true, type, index })}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-fullscreen" viewBox="0 0 16 16">
                                                <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </Col>
                    <Col md={6}>
                        <strong className="main-page-heading"><span>{title}</span></strong>
                        <div className="renders-content">
                            <p><strong>Exterior 360 Renders </strong> {description}</p>
                            <ul>
                                {type === 'interior' ? (
                                    <>
                                        <li>High-quality, immersive cinematic tours of interiors.</li>
                                        <li>Showcase lighting, textures, and design elements dynamically.</li>
                                        <li>Engages clients with realistic, visually compelling content.</li>
                                        <li>Perfect for real estate, interior design, and architecture projects.</li>
                                        <li>Easily shareable and integrable into websites or presentations.</li>
                                    </>
                                ) : (
                                    <>
                                        <li>Fully immersive 360-degree exterior views.</li>
                                        <li>Realistic rendering with high-resolution details.</li>
                                        <li>Enhances client engagement and interaction.</li>
                                        <li>Perfect for real estate, landscaping, and architectural design.</li>
                                        <li>Easy integration into websites for seamless viewing on any device.</li>
                                    </>
                                )}
                            </ul>
                            <Link to="/renders?tab=cinematic"><button >See More</button></Link>
                        </div>
                    </Col>
                </>
            ) : (
                <>
                    <Col md={6}>
                        <strong className="main-page-heading"><span>{title}</span></strong>
                        <div className="renders-content">
                            <p><strong>Interior Cinematic Videos</strong> {description}</p>
                            <ul>
                                {type === 'interior' ? (
                                    <>
                                        <li>High-quality, immersive cinematic tours of interiors.</li>
                                        <li>Showcase lighting, textures, and design elements dynamically.</li>
                                        <li>Engages clients with realistic, visually compelling content.</li>
                                        <li>Perfect for real estate, interior design, and architecture projects.</li>
                                        <li>Easily shareable and integrable into websites or presentations.</li>
                                    </>
                                ) : (
                                    <>
                                        <li>Fully immersive 360-degree exterior views.</li>
                                        <li>Realistic rendering with high-resolution details.</li>
                                        <li>Enhances client engagement and interaction.</li>
                                        <li>Perfect for real estate, landscaping, and architectural design.</li>
                                        <li>Easy integration into websites for seamless viewing on any device.</li>
                                    </>
                                )}
                            </ul>
                            <Link to="/renders?tab=cinematic"><button >See More</button></Link>
                        </div>
                    </Col>
                    <Col md={6}>
                        <Slider {...settings}>
                            {items.map((item, index) => (
                                <div key={index} className="cinematic-videos-images" style={{ cursor: "pointer", width: '100%', margin: 'auto', position: 'relative' }} onClick={() => setOpenModal({ open: true, type, index })}>
                                    <img src={item.thumbnail} alt={`${type} thumbnail`} className="img-fluid" />
                                    <div className="preview-images">
                                        <Link to="/renders?tab=cinematic"><button className="preview-btn">See More</button></Link>
                                        <button className="preview-btn" onClick={() => setOpenModal({ open: true, type, index })}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-fullscreen" viewBox="0 0 16 16">
                                                <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </Col>
                </>
            )}
        </Row>
    );

    return (
        <div className="render-page">
            <div className="pre-design-content">
                {renderSection('interior', 'Interior Cinematic Videos', 'Experience high-quality, immersive tours...', mediaData.interior, false)}
                {renderSection('exterior', 'Exterior Cinematic Videos', 'Fully immersive 360-degree exterior views...', mediaData.exterior, true)}

                {/* Modal for video player */}
                <Modal show={openModal.open} onHide={handleClose} centered className="render-modal-section">
                        <ReactPlayer width="100%" height="100%" url={mediaData[openModal.type][openModal.index]?.src} controls playing />
                        <button onClick={handleClose} className="render-closeBtn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                            </svg>
                        </button>
                </Modal>
            </div>
        </div>
    );
};

export default CinematicVideos;

import React, { useEffect } from "react";
import ProjectComponent from "../components/project/comoponent";

const ProjectPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])
    return (
        <>
            <div className="project-page">
                <ProjectComponent />
            </div>
        </>
    )
}
export default ProjectPage;
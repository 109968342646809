import React, { useRef, useState } from "react";
import ReactPlayer from 'react-player';
import { Modal, Button } from 'react-bootstrap';

const Videos360Tabs = () => {
    const [mediaShow, setMediaShow] = useState<any>(true);
    const [selectedMedia, setSelectedMedia] = useState<any>(null);
    const [open, setOpen] = useState<any>(false);
    const playerRef = useRef<any>(null);
    const [moveHide, setMoveHide] = useState<any>(false)

    const interiorMedia = [
        {
            title: "Green Plaza",
            type: "video",
            src: "https://www.youtube.com/watch?v=WYQITNo0Hmk&list=PLbzBcKDpIG9jXXxtioRw_RCcjCztREVqd&index=3",
            thumbnail: "/assets/images/renders/videos_360/Green_plaza.jpg",
        },
        {
            title: "Green City",
            type: "video",
            src: "https://www.youtube.com/watch?v=i6392pOB4Fc&list=PLbzBcKDpIG9jPUOdZ4bIurqMbHg_5wuBl&index=4",
            thumbnail: "/assets/images/renders/videos_360/Green_City.jpg",
        },
        {
            title: "Alya Konutları Merkezefendi",
            type: "video",
            src: "https://www.youtube.com/watch?v=Sl1gUx48qLI",
            thumbnail: "/assets/images/renders/videos_360/Alya_Konutlari.jpg",
        },
        {
            title: "Valory Güneşli",
            type: "video",
            src: "https://www.youtube.com/watch?v=BQ4ODpTn084&list=PLbzBcKDpIG9jpHsENNVW-rmi1rQ2f35Mg&index=87",
            thumbnail: "/assets/images/renders/videos_360/Valory.jpg",
        },
        {
            title: "Nişantaşı Koru",
            type: "video",
            src: "https://www.youtube.com/watch?v=CN8tKkAQbvE&list=PLbzBcKDpIG9hj4YnWyAeo-iivTIbSEst7&index=4",
            thumbnail: "/assets/images/renders/videos_360/Nisantai.jpg",
        },
        {
            title: "Avrupa Residence Yamanevler",
            type: "video",
            src: "https://www.youtube.com/watch?v=AZGG_q05roA&list=PLbzBcKDpIG9jrPyumHp0t8xdGFv8AgXIB&index=1",
            thumbnail: "/assets/images/renders/videos_360/Yaman.jpg",
        },
        // Add more media objects as needed
    ];

    const handleCloseModal = () => {
        setOpen(false);
        setSelectedMedia(null);
        setMediaShow(true);
    };

    const handleThumbnailClick = (media:any) => {
        setSelectedMedia(media);
        setOpen(true);
        setMoveHide(true);
    };

    return (
        <>
            <div className="renderImages">
                <div className="renderImages360 render-tabs-items">
                    {mediaShow && (
                        <div className="row">
                            {interiorMedia.map((media, index) => (
                                <div key={index} className="col-3" style={{ cursor: "pointer", marginBottom: "20px" }}>
                                    <div style={{ cursor: "pointer", width: '100%', margin: 'auto', position: 'relative' }} className="ImagesBox360" onClick={() => handleThumbnailClick(media)}>
                                        <img
                                            className="img-fluid"
                                            src={media.thumbnail}
                                            alt="Thumbnail"
                                        />
                                        <div className="preview-images-tabs"><small>Preview</small></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <Modal show={open} onHide={handleCloseModal} className="render-modal-section">
                <Modal.Body>
                    {selectedMedia && selectedMedia.type === 'video' && (
                        <ReactPlayer
                            url={selectedMedia.src}
                            className='react-player'
                            playing={true}
                            autoPlay={true}
                            width='100%'
                            height='100%'
                            controls={true}
                            loop={true}
                            ref={playerRef}
                            playsinline={true}
                        />
                    )}
                    <button onClick={handleCloseModal} className="render-closeBtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                        </svg>
                    </button>
                    {
                        moveHide ?
                            <div className="move-direction" onClick={() => { setMoveHide(false) }}>
                                <img src="/assets/images/hand.gif"/>
                            </div>
                            :
                            ""
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Videos360Tabs;

import React, { useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import CinematicVideosTabs from "../components/renders/CinematicVideos";
import Render360Tabs from "../components/renders/Render360Tabs";
import RenderTabs from "../components/renders/RenderTabs";
import Videos360Tabs from "../components/renders/Videos360Tabs";

const RenderPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 500,
            behavior: "smooth"
        });
    }, []);

    const currentTab = new URLSearchParams(location.search).get("tab") || "all";

    const handleSelect = (key: any) => {
        navigate(`?tab=${key}`);
    };

    return (
        <div className="render-page-tabs">
            <div className="render-banner">
                <img src="/assets/images/renders/render_images/interior/1.jpg" className="img-fluid" alt="Render Banner" />
            </div>
            <Container>
                <button className="back-btn" onClick={() => { navigate('/services') }}>Back</button>
                <Tabs
                    activeKey={currentTab}
                    onSelect={handleSelect}
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="all" title="All">
                        <RenderTabs />
                        <CinematicVideosTabs />
                        <Render360Tabs />
                        <Videos360Tabs />
                    </Tab>
                    <Tab eventKey="renders" title="Renders">
                        <RenderTabs />
                    </Tab>
                    <Tab eventKey="cinematic" title="Cinematic Videos">
                        <CinematicVideosTabs />
                    </Tab>
                    <Tab eventKey="videos" title="360 Videos">
                        <Videos360Tabs />
                    </Tab>
                    <Tab eventKey="360renders" title="360 Renders">
                        <Render360Tabs />
                    </Tab>
                </Tabs>
            </Container>
        </div>
    );
};

export default RenderPage;

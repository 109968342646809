import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const RenderTabs = () => {
    const [openLightbox, setOpenLightbox] = useState<any>(false);
    const [currentImageIndex, setCurrentImageIndex] = useState<any>(0);
    const [selectedImages, setSelectedImages] = useState<any>([]);

    // Generate interior and exterior images
    const interiorImages = Array.from({ length: 31 }, (_, i) => ({
        original: `/assets/images/renders/render_images/interior/${i + 1}.jpg`,
    }));

    const exteriorImages = Array.from({ length: 28 }, (_, i) => ({
        original: `/assets/images/renders/render_images/exterior/${i + 1}.jpg`,
    }));

    // Combine both images into one array
    const combinedImages = [...interiorImages, ...exteriorImages];

    useEffect(() => {
        setSelectedImages(combinedImages);
    }, []);

    return (
        <div className="render-page">
            <div className="pre-design-content render-tabs-items">
                <div className="mixed-renders">
                    <Row>
                        {selectedImages.map((img: any, index: any) => (
                            <Col md={3} key={index} onClick={() => {
                                setCurrentImageIndex(index);
                                setOpenLightbox(true);
                            }} >
                                <div style={{ cursor: "pointer", marginBottom: "20px", position: 'relative' }}>
                                    <img className="img-fluid" src={img.original} alt={`Image ${index + 1}`} />
                                    <div className="preview-images-tabs"><small>Preview</small></div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>

            <Lightbox
                open={openLightbox}
                close={() => setOpenLightbox(false)}
                slides={selectedImages.map((img: { original: any }) => ({ src: img.original }))}
                index={currentImageIndex}
            />
        </div>
    );
};

export default RenderTabs;

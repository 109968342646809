import React, { useEffect, useState } from "react";
import { useApp } from "../../../hooks/AppHooks";
import { Col, Row } from "react-bootstrap";
import "./style.scss";
import { Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';

const ImageSection: React.FC = () => {
    const { homeEnglishData } = useApp();
    const multipleImage = homeEnglishData?.projects?.imagesection;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
    }, [multipleImage]);

    if (!multipleImage || multipleImage.length === 0) {
        return <div>Loading...</div>; // Or any other fallback UI
    }

    return (
        <div className="image-section">
            <h3 style={{ textAlign: "center" }}>Feature Projects</h3>
            <Carousel>
                <Carousel.Item interval={300000}>
                    <Row>
                        <Col md={4}>
                            <Row>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[0]?.imageThumbnail}
                                            width={multipleImage[0]?.width}
                                            height={multipleImage[0]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[0]?.title}</h5> <small>({multipleImage[0]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                            <Link to={multipleImage[0]?.link}> <button>{multipleImage[0]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[0]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[1]?.imageThumbnail}
                                            width={multipleImage[1]?.width}
                                            height={multipleImage[1]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[1]?.title}</h5> <small>({multipleImage[1]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[1]?.link}> <button>{multipleImage[1]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[1]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[2]?.imageThumbnail}
                                            width={multipleImage[2]?.width}
                                            height={multipleImage[2]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[2]?.title}</h5> <small>({multipleImage[2]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[2]?.link}> <button>{multipleImage[2]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[2]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[3]?.imageThumbnail}
                                            width={multipleImage[3]?.width}
                                            height={multipleImage[3]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[3]?.title}</h5> <small>({multipleImage[3]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[3]?.link}> <button>{multipleImage[3]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[3]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[4]?.imageThumbnail}
                                            width={multipleImage[4]?.width}
                                            height={multipleImage[4]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[4]?.title}</h5> <small>({multipleImage[4]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[4]?.link}> <button>{multipleImage[4]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[4]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[5]?.imageThumbnail}
                                            width={multipleImage[5]?.width}
                                            height={multipleImage[5]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[5]?.title}</h5> <small>({multipleImage[5]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[5]?.link}> <button>{multipleImage[5]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[5]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <Col md={12} >
                                        <div className="images-items">
                                            <img
                                                src={multipleImage[6]?.imageThumbnail}
                                                width={multipleImage[6]?.width}
                                                height={multipleImage[6]?.height}
                                            />
                                            <div className="image-title">
                                                <h5>{multipleImage[6]?.title}</h5> <small>({multipleImage[6]?.smallTitle})</small>
                                            </div>
                                            <div className="image-overlay">
                                            <Link to={multipleImage[6]?.link}> <button>{multipleImage[6]?.demoTitle}</button></Link>
                                                <button onClick={handleShow} >{multipleImage[6]?.walkTitle}</button>
                                            </div>
                                        </div>
                                    </Col>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[7]?.imageThumbnail}
                                            width={multipleImage[7]?.width}
                                            height={multipleImage[7]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[7]?.title}</h5> <small>({multipleImage[7]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[7]?.link}> <button>{multipleImage[7]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[7]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[8]?.imageThumbnail}
                                            width={multipleImage[8]?.width}
                                            height={multipleImage[8]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[8]?.title}</h5> <small>({multipleImage[8]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[8]?.link}> <button>{multipleImage[8]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[8]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[9]?.imageThumbnail}
                                            width={multipleImage[9]?.width}
                                            height={multipleImage[9]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[9]?.title}</h5> <small>({multipleImage[9]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[9]?.link}> <button>{multipleImage[9]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[9]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[10]?.imageThumbnail}
                                            width={multipleImage[10]?.width}
                                            height={multipleImage[10]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[10]?.title}</h5> <small>({multipleImage[10]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[10]?.link}> <button>{multipleImage[10]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[10]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[11]?.imageThumbnail}
                                            width={multipleImage[11]?.width}
                                            height={multipleImage[11]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[11]?.title}</h5> <small>({multipleImage[11]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[11]?.link}> <button>{multipleImage[11]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[11]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Carousel.Item>
                <Carousel.Item interval={300000}>
                    <Row>
                        <Col md={4}>
                            <Row>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[12]?.imageThumbnail}
                                            width={multipleImage[12]?.width}
                                            height={multipleImage[12]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[12]?.title}</h5> <small>({multipleImage[12]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[12]?.link}> <button>{multipleImage[12]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[12]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[18]?.imageThumbnail}
                                            width={multipleImage[18]?.width}
                                            height={multipleImage[18]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[18]?.title}</h5> <small>({multipleImage[18]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[18]?.link}> <button>{multipleImage[18]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[18]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[15]?.imageThumbnail}
                                            width={multipleImage[15]?.width}
                                            height={multipleImage[15]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[15]?.title}</h5> <small>({multipleImage[15]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[15]?.link}> <button>{multipleImage[15]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[15]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[16]?.imageThumbnail}
                                            width={multipleImage[16]?.width}
                                            height={multipleImage[16]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[16]?.title}</h5> <small>({multipleImage[16]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[16]?.link}> <button>{multipleImage[16]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[16]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[20]?.imageThumbnail}
                                            width={multipleImage[20]?.width}
                                            height={multipleImage[20]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[20]?.title}</h5> <small>({multipleImage[20]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[20]?.link}> <button>{multipleImage[20]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[20]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[17]?.imageThumbnail}
                                            width={multipleImage[17]?.width}
                                            height={multipleImage[17]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[17]?.title}</h5> <small>({multipleImage[17]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[17]?.link}> <button>{multipleImage[17]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[17]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[13]?.imageThumbnail}
                                            width={multipleImage[13]?.width}
                                            height={multipleImage[13]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[13]?.title}</h5> <small>({multipleImage[13]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[13]?.link}> <button>{multipleImage[13]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[13]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[19]?.imageThumbnail}
                                            width={multipleImage[19]?.width}
                                            height={multipleImage[19]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[19]?.title}</h5> <small>({multipleImage[19]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[19]?.link}> <button>{multipleImage[19]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[19]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} >
                                    <div className="images-items">
                                        <img
                                            src={multipleImage[14]?.imageThumbnail}
                                            width={multipleImage[14]?.width}
                                            height={multipleImage[14]?.height}
                                        />
                                        <div className="image-title">
                                            <h5>{multipleImage[14]?.title}</h5> <small>({multipleImage[14]?.smallTitle})</small>
                                        </div>
                                        <div className="image-overlay">
                                        <Link to={multipleImage[14]?.link}> <button>{multipleImage[14]?.demoTitle}</button></Link>
                                            <button onClick={handleShow} >{multipleImage[14]?.walkTitle}</button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Carousel.Item>
            </Carousel>
            <Modal show={show} onHide={handleClose} className="project-demo-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <video width={'100%'} height={'100%'} preload="auto" autoPlay playsInline controls loop style={{ borderRadius: "7px" }} muted>
                        <source src="/assets/images/projects/cuverse.mp4" type="video/mp4" />
                    </video>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ImageSection;
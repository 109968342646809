import React, { useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel';
import "./style.scss";

interface BannerCarouselProps {
    bannerImage: any; // You can replace `any` with the specific type if known
}
const BannerCarousel: React.FC<BannerCarouselProps> = ({ bannerImage }) => {

    return (
        <>
            <div className="banner-caraousel">
                <Carousel pause={false}>
                    {
                        bannerImage?.map((item: any, index: number) => {
                            return (
                                <Carousel.Item key={index} interval={2000} >
                                    <Carousel.Caption>
                                        <img src={item} className="img-fluid" />
                                    </Carousel.Caption>
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </div>
        </>
    )
}
export default BannerCarousel;
import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import BimSection from "../components/service/BimSection";
import GreenBuilding from "../components/service/GreenBuilding";
import VideoComponent from "../components/home/videoSection/component";
import { useApp } from "../hooks/AppHooks";
import RenderPage from "../components/service/Render";
import CinematicVideosPage from "../components/service/Cinematicvideos";
import RenderPage360 from "../components/service/Render360";

const ServicePage: React.FC = () => {
    const { homeEnglishData } = useApp();
    const videourl = homeEnglishData?.services;
    console.log(videourl, "videourl")
    // Create refs for each section
    const preDesignRef = useRef<HTMLElement | null>(null);
    const bimRef = useRef<HTMLElement | null>(null);
    const greenBuildingRef = useRef<HTMLElement | null>(null);
    const rendersRef = useRef<HTMLElement | null>(null);
    const cinematicVideosRef = useRef<HTMLElement | null>(null);
    const renders360Ref = useRef<HTMLElement | null>(null);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    // Function to scroll to a specific section
    const scrollToSection = (sectionRef: React.RefObject<HTMLElement>) => {
        if (sectionRef.current) {
            const elementPosition = sectionRef.current.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - 50; // Subtract 100px for the margin

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    };

    return (
        <>

            <div className="service-section home-page">
                {/* Attach refs to the components */}
                <section ref={preDesignRef}>
                    {/* <PreDesign /> */}
                    <VideoComponent videourl={videourl} />
                </section>
                <Container>
                    <h3 className="main-page-heading" style={{ textAlign: "center" }}>
                        <span>Our Services</span>
                    </h3>
                    <ul className="service-list">
                        <li onClick={() => scrollToSection(rendersRef)}>Renders</li>
                        <li onClick={() => scrollToSection(cinematicVideosRef)}>Cinematic Videos</li>
                        <li onClick={() => scrollToSection(renders360Ref)}>360 Renders</li>
                        <li onClick={() => scrollToSection(bimRef)}>BIM</li>
                        <li onClick={() => scrollToSection(greenBuildingRef)}>Green Building</li>
                    </ul>
                </Container>
                <Container>
                    <section ref={rendersRef}>
                        <RenderPage />
                    </section>
                    <section ref={cinematicVideosRef}>
                        <CinematicVideosPage />
                    </section>
                    <section ref={renders360Ref}>
                        <RenderPage360 />
                    </section>
                    <section>
                        {/* <Videos360 /> */}
                    </section>
                    <section ref={bimRef}>
                        <BimSection />
                    </section>
                    <section ref={greenBuildingRef}>
                        <GreenBuilding />
                    </section>
                </Container>
            </div>
        </>
    );
};

export default ServicePage;

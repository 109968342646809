import React from "react";

const BlogsPage = () => {
    return (
        <>
            <div className="faq-page  coming-soon">
                <h1>Coming Soon</h1>
            </div>
        </>
    )
}
export default BlogsPage;
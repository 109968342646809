import React, { useEffect } from "react";
import BannerCarousel from "../components/bannerCaraousel/component";
import { useApp } from "../hooks/AppHooks";
import { Container } from "react-bootstrap";
import TabSection from "../components/developers/tabsection/component";
import ProjectList from "../components/developers/Projects/component";
import WhyTurstUsComponent from "../components/developers/whyTrustUs/component";

const DeveloperPage: React.FC = () => {
    const { homeEnglishData } = useApp();
    const bannerImage = homeEnglishData?.developers?.bannerSection;
    const developerData = homeEnglishData?.developers;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])
    return (
        <>
            <div className="deveoper-page">
                <BannerCarousel bannerImage={bannerImage} />
                <Container>
                    <TabSection
                        developerData={developerData}
                    />
                    <ProjectList
                        developerData={developerData}
                    />
                    <WhyTurstUsComponent
                        developerData={developerData}
                    />
                </Container>
            </div>
        </>
    )
}
export default DeveloperPage;
import React, { useEffect, useRef, useState } from 'react';
import {
    Navbar,
    Nav,
    Container
} from 'react-bootstrap';
import { useApp } from '../../hooks/AppHooks';
import './style.scss';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface Props {
    window?: () => Window;
}

const HeaderC: React.FC<Props> = () => {
    const { homeEnglishData } = useApp();
    const dataItem = homeEnglishData?.home?.header;
    const [currentActive, setCurrentActive] = useState<number | null>(null);
    const [headerShow, setHeaderShow] = useState(false);
    const prevScrollPos = useRef(0);
    const checkboxRef = useRef<HTMLInputElement>(null); // Reference to the checkbox

    useEffect(() => {
        const savedIndex = localStorage.getItem('activeIndex');
        if (savedIndex !== null) {
            setCurrentActive(Number(savedIndex));
        }
    }, []);

    useEffect(() => {
        const toggleVisibility = () => {
            const currentScrollPos = window.pageYOffset;
            if (currentScrollPos > 0 && currentScrollPos > prevScrollPos.current) {
                setHeaderShow(false); // Automatically hide header when scrolling down
                if (checkboxRef.current) {
                    checkboxRef.current.checked = false; // Uncheck the checkbox
                }
            } else {
            }
            prevScrollPos.current = currentScrollPos;
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const onClickHeaderToggle = (event: any) => {
        const isChecked = event.target.checked;
        console.log('Checkbox is checked:', isChecked);
        setHeaderShow(isChecked);
    }
    const onClickHandlerHide = () => {
        setHeaderShow(false);
        if (checkboxRef.current) {
            checkboxRef.current.checked = false; // Uncheck the checkbox
        }
    }

    return (
        <div className="header-section">
            <Container className='cubedots-logo'>
                <Link to="/"><img src='./assets/images/logo.png' alt="Footer Logo" width={'40px'} /></Link>
                <label className="burger" htmlFor="burger">
                    <input
                        ref={checkboxRef}
                        onClick={onClickHeaderToggle}
                        type="checkbox"
                        id="burger"
                    />
                    <span
                        style={{}}
                    ></span>
                    <span></span>
                    <span></span>
                </label>
                {/* </div> */}
            </Container>
            <Navbar bg="dark" variant="dark" expand="lg" fixed="top" style={{ transition: "0.5s", transform: !headerShow ? "translate(100%, 0%)" : "translate(0%, 0)" }}>
                <Container>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            {dataItem?.menu?.map((item: any, index: number) => (
                                <div key={index} className={`menu-items menu-class${index}${currentActive === index ? ' active' : ''}`}>
                                    <NavLink
                                        onClick={onClickHandlerHide}
                                        to={item.link}
                                    >
                                        {item.menuItems}
                                    </NavLink>
                                </div>
                            ))}
                            <div id="google_translate_element" style={{ width: '136px' }}></div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default HeaderC;
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const RenderPage = () => {
    const [openInterior, setOpenInterior] = useState(false);
    const [openExterior, setOpenExterior] = useState(false);
    const [selectedImages, setSelectedImages] = useState<any>([]);
    const [selectedImagesExterior, setSelectedImagesExterior] = useState<any>([]);
    const [startIndexInterior, setStartIndexInterior] = useState(0);  // Track the selected interior image index
    const [startIndexExterior, setStartIndexExterior] = useState(0);  // Track the selected exterior image index

    const interiorImages = Array.from({ length: 31 }, (_, i) => ({
        original: `/assets/images/renders/render_images/interior/${i + 1}.jpg`,
    }));

    const exteriorImages = Array.from({ length: 28 }, (_, i) => ({
        original: `/assets/images/renders/render_images/exterior/${i + 1}.jpg`,
    }));

    useEffect(() => {
        setSelectedImages(interiorImages);
        setSelectedImagesExterior(exteriorImages);
    }, []);

    const handleInteriorClick = (index: number) => {
        setStartIndexInterior(index);  // Set the clicked image index
        setOpenInterior(true);         // Open the Lightbox
    };

    // Function to handle when an exterior image is clicked
    const handleExteriorClick = (index: number) => {
        setStartIndexExterior(index);  // Set the clicked image index
        setOpenExterior(true);         // Open the Lightbox
    };

    const renderSlider = (images: any, handleClick: any, setOpen: any) => (
        <Slider {...{ dots: true, infinite: true, speed: 500, slidesToShow: 1 }}>
            {images.slice(0, 3).map((img: any, index: any) => (
                <div key={index} onClick={() => handleClick(index)} style={{ cursor: "pointer" }}>
                    <img className="img-fluid" src={img.original} alt={`Image ${index + 1}`} />
                    <div className="preview-images"><small>Preview</small></div>
                </div>
            ))}
        </Slider>
    );

    return (
        <div className="render-page">
            <div className="pre-design-content">
                <div className="interior-renders" >
                    <Row>
                        <Col md={6}>
                            <strong className="main-page-heading" style={{ textAlign: "center" }}>
                                <span>Interior Renders</span>
                            </strong>
                            <div className="renders-content">
                                <p><strong>Interior Renders</strong> provide photorealistic, detailed views of interior spaces, helping you visualize designs with precision. Ideal for real estate, interior design, and architectural projects, these renders showcase layouts and decor beautifully.</p>
                                <ul>
                                    <li>Photorealistic, high-quality renderings of interior spaces.</li>
                                    <li>Highlights furniture, lighting, textures, and materials.</li>
                                    <li>Ideal for real estate, interior design, and architectural visualization.</li>
                                    <li>Enhances client presentations with accurate and stunning visuals.</li>
                                    <li>Easily integrable into websites and marketing materials.</li>
                                </ul>
                                <Link to="/renders?tab=renders"><button >See More</button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div style={{ cursor: "pointer", width: '100%', margin: 'auto', position: 'relative' }}>
                                {renderSlider(selectedImages, handleInteriorClick, setOpenInterior)}
                                <Lightbox
                                    open={openInterior}
                                    close={() => setOpenInterior(false)}
                                    slides={interiorImages.map(img => ({ src: img.original }))}
                                    index={startIndexInterior}  // Pass the clicked index to Lightbox
                                />
                                <div className="preview-images">
                                    <Link to="/renders?tab=renders"><button className="preview-btn">See More</button></Link>
                                    <button className="preview-btn" onClick={() => { handleInteriorClick(startIndexInterior) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-fullscreen" viewBox="0 0 16 16">
                                            <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col md={6}>
                        <div style={{ cursor: "pointer", width: '100%', margin: 'auto', position: 'relative' }}>
                            {renderSlider(selectedImagesExterior, handleExteriorClick, setOpenExterior)}
                            <Lightbox
                                open={openExterior}
                                close={() => setOpenExterior(false)}
                                slides={exteriorImages.map(img => ({ src: img.original }))}
                                index={startIndexExterior}  // Pass the clicked index to Lightbox
                            />
                            <div className="preview-images" >
                                <Link to="/renders?tab=renders"><button className="preview-btn">See More</button></Link>
                                <button className="preview-btn" onClick={() => { handleExteriorClick(startIndexExterior) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-fullscreen" viewBox="0 0 16 16">
                                        <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <strong className="main-page-heading" style={{ textAlign: "center" }}>
                            <span>Exterior Renders</span>
                        </strong>
                        <div className="renders-content">
                            <p><strong>Exterior Renders</strong> offer photorealistic, high-quality visuals of building exteriors, showcasing architectural details and landscapes. Perfect for real estate and architectural projects, these renders highlight every aspect of outdoor design.</p>
                            <ul>
                                <li>Photorealistic exterior views of buildings and landscapes.</li>
                                <li>Showcases lighting, textures, and materials with precision.</li>
                                <li>Ideal for real estate, architecture, and outdoor design.</li>
                                <li>Enhances presentations with stunning, detailed visuals.</li>
                                <li>Easily integrated into websites and marketing materials.</li>
                            </ul>
                            <Link to="/renders?tab=renders"><button >See More</button></Link>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default RenderPage;

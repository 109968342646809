import React, { useEffect, useRef, useState, useCallback } from "react";
import Slider from "react-slick";
import 'animate.css';
import { useApp } from "../../../hooks/AppHooks";
import { Container } from "react-bootstrap";
import throttle from "lodash.throttle";

interface VideoUrlLink {
    videourl: any;
}

const VideoComponent: React.FC<VideoUrlLink> = ({ videourl }) => {
    const [duration, setDuration] = useState<number | null>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [sliderIndex, setSliderIndex] = useState<number>(0);
    const sliderRef = useRef<Slider>(null);
    const videoRefs = useRef<HTMLVideoElement[]>([]);
    const { homeEnglishData } = useApp();
    // const videourl = homeEnglishData?.home;
    const [currentTime, setCurrentTime] = useState<number>(0);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (index: number) => {
            handleSlideChange(index);
        },
    };
    const togglePlay = () => {
        const currentVideo = videoRefs.current[sliderIndex];
        if (isPlaying) {
            currentVideo?.pause();
            setIsPlaying(false);
        } else {
            currentVideo?.play();
            setIsPlaying(true);
        }
    };

    const throttledTimeUpdate = useCallback(
        throttle((currentTime: number) => {
            setCurrentTime(Math.floor(currentTime)); // Ignore decimal part
        }, 1000), // Adjust the delay as needed (in milliseconds)
        []
    );

    const handleTimeUpdate = (event: any) => {
        const currentTime = event.target.currentTime;
        throttledTimeUpdate(currentTime);
    };

    const handleSlideChange = (index: number) => {
        const currentVideo = videoRefs.current[sliderIndex];
        if (currentVideo) {
            currentVideo.pause();
            currentVideo.currentTime = 0;
            setIsPlaying(false);
        }

        // Reset all relevant state variables
        setSliderIndex(index);
        setDuration(null); // Reset duration
        setCurrentTime(0); // Reset current time
        setIsPlaying(false); // Reset play state

        const newVideo = videoRefs.current[index];
        if (newVideo) {
            newVideo.currentTime = 0; // Reset video time to start
            newVideo.play().catch((error) => console.log(error)); // Play the video
            setIsPlaying(true);
            setDuration(Math.floor(newVideo.duration)); // Update duration and ignore decimal part

            // Remove existing event listener to prevent multiple listeners
            newVideo.removeEventListener("ended", handleVideoEnded);

            // Add event listener for video end
            newVideo.addEventListener("ended", handleVideoEnded);
        }
    };

    const handleListItemClick = (index: number) => {
        const currentVideo = videoRefs.current[sliderIndex];
        if (currentVideo) {
            currentVideo.pause();
            currentVideo.currentTime = 0;
            setIsPlaying(false);
        }

        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index);
        }
        // handleSlideChange(index);
    };

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleLoadedData = (event: any, index: number) => {
        const duration = event.target.duration;
        if (index === sliderIndex) {
            setDuration(Math.floor(duration));

            if (sliderRef.current) {
                sliderRef.current.slickGoTo(index);
            }
        }
    };

    const handleVideoEnded = () => {
        const currentVideo = videoRefs.current[sliderIndex];
        if (currentVideo) {
            currentVideo.pause();
            currentVideo.currentTime = 0;
            setIsPlaying(false);
        }

        if (sliderRef.current) {
            const nextIndex = (sliderIndex + 1) % (videourl?.videosection?.length || 1);
            sliderRef.current.slickGoTo(nextIndex);
        }
    };

    useEffect(() => {
        videoRefs.current = Array(videourl?.videosection?.length || 0)
            .fill(0)
            .map((_, i) => videoRefs.current[i] || null);
    }, [videourl?.videosection?.length]);

    useEffect(() => {
        const currentVideo = videoRefs.current[sliderIndex];
        if (currentVideo) {
            currentVideo.addEventListener("ended", handleVideoEnded);
            return () => {
                currentVideo.removeEventListener("ended", handleVideoEnded);
            };
        }
    }, [sliderIndex]);

    return (
        <div className="video-section">
            <Slider {...settings} initialSlide={sliderIndex} ref={sliderRef}>
                {videourl?.videosection?.map((videoUrl: any, index: number) => (
                    <div key={index}>
                        <video
                            ref={(el) => (videoRefs.current[index] = el as HTMLVideoElement)}
                            src={videoUrl.videoLink}
                            onLoadedData={(event) => handleLoadedData(event, index)}
                            playsInline
                            autoPlay
                            muted
                            preload="auto"
                            onTimeUpdate={handleTimeUpdate}
                            width={800}
                            height={800}
                            onPlay={handlePlay}
                            onPause={handlePause}
                        />
                    </div>
                ))}
            </Slider>
            <div className="video-title-box">
                <Container>
                    <div className="video-heading">
                        {/* <h3>{videoUrl?.heading}</h3> */}
                    </div>
                    <ul className="video-content">
                        {videourl?.videosection?.map((videoUrl: any, index: number) => (
                            <li
                                key={index}
                                className={sliderIndex === index ? "active" : ""}
                                onClick={() => handleListItemClick(index)}
                            >
                                <p>{videoUrl?.firstNumber}</p>
                                <p>{videoUrl?.subHeading1}</p>
                            </li>
                        ))}
                    </ul>
                    <div className="progress-box">
                        <div className="progress-bar-line">
                            <small
                                style={{
                                    position: "absolute",
                                    right: "0",
                                    color: "#f2f5f7",
                                    top: "5px",
                                    fontSize: "12px"
                                }}
                            >{`${Math.floor(currentTime / 60)}:${Math.floor(currentTime % 60).toString().padStart(2, '0')}`}</small>
                            <small
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    color: "#f2f5f7",
                                    top: "5px",
                                    fontSize: "12px"
                                }}
                            >{duration !== null ? `${Math.floor(duration / 60)}:${Math.floor(duration % 60).toString().padStart(2, '0')}` : "0:00"}</small>
                            <div className="progress-bar">
                                <div
                                    className="progress"
                                    style={{
                                        width: `${(currentTime / (duration || 1)) * 100}%`
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <Container>
                <div className="playBtnBox">
                    <button className="tooglePlayBtn" onClick={togglePlay}>
                        {isPlaying ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#1E2430" className="bi bi-pause-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0z" />
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#1E2430" className="bi bi-play-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445" />
                            </svg>}
                    </button>
                </div>
                <div className="curve-image">
                    <img src="/assets/images/curve.png" />
                </div>
            </Container>
        </div>
    );
};

export default VideoComponent;
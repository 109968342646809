import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import Modal from 'react-bootstrap/Modal';

const CinematicVideosTabs = () => {
    const [openModal, setOpenModal] = useState({ open: false, index: 0 });
    const handleClose = () => setOpenModal({ open: false, index: 0 });

    const mediaData = {
        interior: [
            { src: "https://youtu.be/cwhzCOkuctA", thumbnail: "/assets/images/renders/cinematic/interior/alya.jpg" },
            { src: "https://youtu.be/uI1vaWlyqZU", thumbnail: "/assets/images/renders/cinematic/interior/green_city.jpg" },
            { src: "https://youtu.be/IqiuH_l98uA", thumbnail: "/assets/images/renders/cinematic/interior/green_plaza.jpg" },
            { src: "https://youtu.be/mHt70pdadZk", thumbnail: "/assets/images/renders/cinematic/exterior/green_city.jpg" },
            { src: "https://youtu.be/YGMJVVe3Ydw", thumbnail: "/assets/images/renders/cinematic/exterior/valory.jpg" },
            { src: "https://youtu.be/4mW_agb-5jc", thumbnail: "/assets/images/renders/cinematic/exterior/yaman.jpg" }
        ]
    };

    const renderSection = (items: Array<any>) => (
        <Row>
            {items.map((item, index) => (
                <Col md={3}>
                    <div style={{ cursor: "pointer", marginBottom: "20px", position: 'relative' }} key={index} onClick={() => setOpenModal({ open: true, index })}>
                        <img src={item.thumbnail} className="img-fluid" alt="Video thumbnail" />
                        <div className="preview-images-tabs"><small>Preview</small></div>
                    </div>
                </Col>
            ))}
        </Row>
    );

    return (
        <div className="render-page">
            <div className="pre-design-content render-tabs-items">
                {/* First section: Interior */}
                {renderSection(mediaData.interior)}
            </div>

            {openModal.open && (
                <Modal show={openModal.open} onHide={handleClose} className="render-modal-section">
                    <ReactPlayer
                        url={mediaData.interior[openModal.index].src}
                        playing
                        controls
                        width="100%"
                        height="100%"
                    />
                    <button onClick={handleClose} className="render-closeBtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                        </svg>
                    </button>
                </Modal>
            )}
        </div>
    );
};

export default CinematicVideosTabs;

import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useApp } from '../../hooks/AppHooks';
import { Link } from 'react-router-dom';
import "./style.scss";

const FooterC: React.FC = () => {
    const { homeEnglishData } = useApp();
    const data = homeEnglishData?.home;
    const [selectedIcon, setSelectedIcon] = useState<string | null>(null);
    const [iconPosition, setIconPosition] = useState<{ x: number, y: number } | null>(null);
    const [toggleId, setToggleId] = useState<any>();
    const [toggleOpen, setToggleOpen] = useState<boolean>(false);

    const boxRef = useRef<HTMLDivElement>(null);

    const svgLoad = () => {
        const objTag = document.querySelector('object') as HTMLObjectElement | null;
        if (objTag) {
            objTag.addEventListener('load', () => {
                const svgDoc = objTag.contentDocument;
                if (svgDoc) {
                    const paths = Array.from(svgDoc.getElementsByTagName('path'));
                    paths.forEach(p => {
                        if (p.getAttribute('id') && p.getAttribute('id')!.toLowerCase() !== 'map') {
                            p.addEventListener('click', onIconClick);
                        }
                    });

                    paths.forEach(p => {
                        if (p.getAttribute('class') === 'cls-1') {
                            p.setAttribute('cursor', 'pointer');
                            p.addEventListener('click', onClickPath);
                            p.addEventListener('mouseover', onIconHover);
                            p.addEventListener('mouseleave', onIconOut);
                        }
                    });
                }
            });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const onIconOut = (ev: Event) => {
        const t = ev.target as SVGPathElement;
        t.setAttribute('style', "opacity:1;fill:#08428D");
    };

    const onIconClick = (ev: MouseEvent) => {
        const t = ev.target as SVGPathElement;
        const currentId = t.getAttribute('id');
        setToggleId(currentId);
        const boundingRect = t.getBoundingClientRect();
        const centerX = boundingRect.left + boundingRect.width;
        const centerY = boundingRect.top + boundingRect.height;

        setSelectedIcon(currentId);
        setIconPosition({ x: centerX, y: centerY });
    };

    const onClickPath = (ev: Event) => {
        setToggleOpen(prev => !prev);
        const t = ev.target as SVGPathElement;
        t.setAttribute('style', "opacity:1;fill:#08428D;fill-opacity:1;stroke:#08428D;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1");
    };

    const onIconHover = (ev: Event) => {
        const t = ev.target as SVGPathElement;
        t.setAttribute('style', "opacity:0.9;fill:#f2f5f7");
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
            setToggleOpen(false);
        }
    };

    useEffect(() => {
        svgLoad();
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectedIcon, toggleId, toggleOpen]);

    return (
        <div className="footerSection">
            <Container>
                <Row style={{ justifyContent: "space-between" }}>
                    <Col md={6} lg={4}>
                        <Row>
                            <Col md={10}>
                                <img src='./assets/images/logo.png' alt="Footer Logo" width={"10%"} />
                                <p style={{ marginTop: '18px' }}>Founded on the principle of continuous improvement, Cubedots pioneered technological solutions in real estate. We remain at the forefront, constantly evolving our tech offerings to elevate the client experience. We were the first to leverage technology in the real estate market. Tech + Touch: We build lasting agency partnerships worldwide, valuing personal connections alongside technology's power.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} lg={2}>
                        <Row>
                            <Col md={12}>
                                <h3>{data?.footer?.quickLink}</h3>
                                <div className='footer-items' style={{ display: "flex" }}>
                                    <div>
                                        {
                                            data?.footer?.firstSlide?.map((data: any, index: number) => {
                                                return (
                                                    <Link onClick={scrollToTop} to={data?.link}><p>{data?.title}</p></Link>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={5}>
                        <div className='position-relative'>
                            <object
                                data="assets/images/map.svg"
                                aria-label="block view"
                            />
                            {selectedIcon && iconPosition && toggleOpen && (
                                <div
                                    ref={boxRef}
                                    className="toggle-info"
                                    style={{
                                        left: `calc(${iconPosition.x}px - 2%)`,
                                        top: `calc(${iconPosition.y}px - 35%)`,
                                        position: "absolute",
                                        transform: 'translate(-50%, -50%)'
                                    }}
                                >
                                    {toggleId == 123 ?
                                        data?.footer?.contactUs?.contactItem?.map((item: any, index: number) => (
                                            <div className='d-flex' style={{ alignItems: "center" }} key={index}>
                                                <img width={'10px'} style={{ marginRight: '5px' }} src={item?.icon1} alt={item?.contactNumber} />
                                                <small>{item?.contactNumber}</small>
                                                <small>{item?.india}</small>
                                            </div>
                                        ))
                                        :
                                        data?.footer?.contactUs?.contactItem1?.map((item: any, index: number) => (
                                            <div className='d-flex' key={index}>
                                                <img width={'10px'} style={{ marginRight: '5px' }} src={item?.icon1} alt={item?.contactNumber} />
                                                <small>{item?.contactNumber}</small>
                                            </div>
                                        ))
                                    }
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row style={{alignItems:"center"}}>
                    <Col><p  style={{ color: "#afaeae" }}><a className='terms-condition' href='#' style={{ color: "#afaeae", cursor: "pointer", textDecoration:"none" }}>Terms & Conditions </a></p></Col>
                    <Col><p className='text-end' style={{ color: "#afaeae" }}>Copyright ©  2024 Realty Verse. All Rights Reserved.</p></Col>
                </Row>
            </Container>
        </div>
    );
};

export default FooterC;

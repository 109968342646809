import React from "react";
import BannerCarousel from "../bannerCaraousel/component";
import ImageSection from "./imagesection/component";
import "./Project.scss"
import { Container } from "react-bootstrap";
import { useApp } from "../../hooks/AppHooks";

const ProjectComponent: React.FC = () => {
    const { homeEnglishData } = useApp();
    const bannerImage = homeEnglishData?.projects?.bannerSection;
    return (
        <>
            <BannerCarousel bannerImage={bannerImage} />
            <Container>
                <div className="project-heading-section">
                    <h3  className="main-page-heading" style={{ textAlign: "center" }}><span>Projects</span></h3>
                    <h3>A Glimpse into Extraordinary Projects</h3>
                    <p>We believe in the power of working alongside talented developers and architects. This page, titled "The Power of Collaboration: Bringing Visions to Life," is a testament to that spirit. Explore our diverse portfolio, a symphony of expertise showcasing how we've partnered with industry leaders to translate their unique visions into stunning 3D visualizations. Each project is a testament to the symphony that plays out when talented minds come together. Witness how we translate your dreams into captivating visuals, collaborating seamlessly to ensure every detail reflects your unique vision. These aren't just stunning renderings; they're shared success stories that showcase not only the beauty of the final product, but also the innovative spirit that thrives through powerful collaboration. Are you a forward-thinking developer or architect with a groundbreaking vision waiting to be realized? We're passionate about pushing boundaries and crafting visuals that elevate your projects to new heights. </p>
                </div>
                <ImageSection />
            </Container>
        </>
    )
}
export default ProjectComponent;
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Slider from "react-slick";
import ReactPannellum from "react-pannellum";
import { Link } from "react-router-dom";

const RenderPage360 = () => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<any>(0);
    const [modalType, setModalType] = useState<'interior' | 'exterior' | null>(null);
    const [selectedImages, setSelectedImages] = useState<any[]>([]);
    const [selectedImagesExterior, setSelectedImagesExterior] = useState<any[]>([]);
    const [show, setShow] = useState(false);
    const [moveHide, setMoveHide] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const interiorMedia = Array.from({ length: 13 }, (_, i) => ({
        src: `/assets/images/renders/render_360/interior/${i + 1}.jpg`,
    }));

    const exteriorMedia = Array.from({ length: 6 }, (_, i) => ({
        src: `/assets/images/renders/render_360/exterior/${i + 1}.jpg`,
    }));

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoPlay: true
    };

    const handleThumbnailClick = (index: number, type: 'interior' | 'exterior') => {
        setSelectedIndex(index);
        setModalType(type);
        setOpenModal(true);
        setTimeout(() => {
            setMoveHide(true)
        }, 1000);
        handleShow();
    };

    useEffect(() => {
        setSelectedImages(interiorMedia);
        setSelectedImagesExterior(exteriorMedia);
    }, []);

    return (
        <div className="render-page">
            <div className="pre-design-content">
                {/* Interior Renders */}
                <div className="interior-renders">
                    <Row>
                        <Col md={6}>
                            <strong className="main-page-heading" style={{ textAlign: "center" }}>
                                <span>Interior 360 Renders</span>
                            </strong>
                            <div className="renders-content">
                                <p><strong>Interior 360 Renders</strong> provide an immersive, interactive view of interior spaces, allowing users to explore designs from every angle. Ideal for real estate and interior design, these renders bring spaces to life with stunning realism.</p>
                                <ul>
                                    <li>Immersive 360-degree views of interior spaces.</li>
                                    <li>High-resolution, interactive visuals that engage clients.</li>
                                    <li>Perfect for real estate, interior design, and architectural projects.</li>
                                    <li>Showcases layouts, lighting, and decor from all perspectives.</li>
                                    <li>Easily integrated into websites for seamless navigation.</li>
                                </ul>
                                <Link to="/renders?tab=360renders"><button >See More</button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <Slider {...settings}>
                                {
                                    selectedImages.slice(0, 3).map((row: any, index: number) => (
                                        <div key={index} style={{ cursor: "pointer", marginBottom: "10px" }}>
                                            <div className="renderImagesBox" style={{ cursor: "pointer", width: '100%', margin: 'auto', position: 'relative' }} onClick={() => handleThumbnailClick(index, 'interior')}>
                                                <img className="img-fluid" src={row.src}

                                                />
                                                <div className="preview-images">
                                                    <Link to="/renders?tab=360renders"><button className="preview-btn">See More</button></Link>
                                                    <button className="preview-btn" onClick={() => handleThumbnailClick(index, 'interior')}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-fullscreen" viewBox="0 0 16 16">
                                                            <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </Col>
                    </Row>
                </div>

                {/* Exterior Renders */}
                <div className="exterior-renders">
                    <Row>
                        <Col md={6}>
                            <Slider {...settings}>
                                {
                                    selectedImagesExterior.slice(0, 3).map((row: any, index: number) => (
                                        <div key={index} style={{ cursor: "pointer", marginBottom: "10px" }}>
                                            <div className="renderImagesBox" style={{ cursor: "pointer", width: '100%', margin: 'auto', position: 'relative' }} onClick={() => handleThumbnailClick(index, 'exterior')}>
                                                <img className="img-fluid" src={row.src} />
                                                <div className="preview-images">
                                                    <Link to="/renders?tab=360renders"><button className="preview-btn">See More</button></Link>
                                                    <button className="preview-btn" onClick={() => handleThumbnailClick(index, 'interior')}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-fullscreen" viewBox="0 0 16 16">
                                                            <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </Col>
                        <Col md={6}>
                            <strong className="main-page-heading" style={{ textAlign: "center" }}>
                                <span>Exterior 360 Renders</span>
                            </strong>
                            <div className="renders-content">
                                <p><strong>Exterior 360 Renders </strong> offer an immersive, interactive view of building exteriors, allowing users to explore architectural designs from all angles. Perfect for real estate and architectural presentations, these renders enhance client engagement with stunning realism.</p>
                                <ul>
                                    <li>Interactive 360-degree views of exterior spaces.</li>
                                    <li>High-quality, immersive visuals that captivate clients.</li>
                                    <li>Ideal for real estate, architecture, and landscape design.</li>
                                    <li>Showcases architectural details and outdoor environments.</li>
                                    <li>Easily embedded into websites for seamless exploration.</li>
                                </ul>
                                <Link to="/renders?tab=360renders"><button >See More</button></Link>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* Modal for both interior and exterior */}
                {openModal && modalType && (
                    <Modal show={show} onHide={handleClose} className="video-modal render-modal-section">
                        <ReactPannellum
                            id="1"
                            width={'100%'}
                            height={'100%'}
                            sceneId="firstScene"
                            imageSource={modalType === 'interior' ? selectedImages[selectedIndex]?.src : selectedImagesExterior[selectedIndex]?.src}
                            config={{
                                autoLoad: true,
                                mouseZoom: false,
                                disableZoom: true,
                                hfov: 130,
                            }}
                        />
                        <button onClick={handleClose} className="render-closeBtn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                            </svg>
                        </button>
                        {
                            moveHide ?
                                <div className="move-direction" onClick={() => { setMoveHide(false) }}>
                                    <img src="/assets/images/hand.gif" />
                                </div>
                                :
                                ""
                        }

                    </Modal>
                )}
            </div>
        </div>
    );
};

export default RenderPage360;

import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import VideoComponent from "../home/videoSection/component";
import AOS from "aos";
import "aos/dist/aos.css";

interface CuversePropsData {
    videourl: any;
}

const OurOffering: React.FC<CuversePropsData> = ({ videourl }) => {

    const floorplanVideoRef = useRef<HTMLVideoElement>(null);
    const exteriorVideoRef = useRef<HTMLVideoElement>(null);
    const video360Ref = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <div className="our-offering-section">
            <div className="home-page">
                <VideoComponent videourl={videourl} />
                <Container>
                    <div className="transform" style={{ marginTop: "50px" }}>
                        <Row style={{ alignItems: 'center', overflow: 'hidden' }}>
                            <Col md={4} style={{ textAlign: "center" }} data-aos="fade-up">
                                <h3>What's</h3>
                                <h3>"Realty Verse ?"</h3>
                                <div className="small-text-cuverse">
                                    <small>The ultimate tool for developers </small>
                                    <small style={{ display: "block" }}>who demand the best.</small>
                                </div>
                            </Col>
                            <Col md={8} data-aos="fade-down">
                                <p>
                                    Realty Verse, is our comprehensive bouquet of services designed to transform how you present and market your real estate developments. Realty Verse includes everything you need & seen above: detailed floor plans, stunning still renders, immersive cinematic videos, surrounding details, amenities information, and interactive panatours. But it goes beyond mere visualization; Realty Verse empowers agents to check live stock and filter apartments according to customers' needs, all within a single platform. Accessible from any web browser and unrestricted by any platform, Realty Verse brings all these powerful materials just one click away, making it globally accessible. With Realty Verse, you can provide a seamless, engaging, and dynamic experience to stakeholders, ensuring your project stands out in the competitive market and reaches audiences worldwide. Elevate your real estate marketing with Realty Verse, where all your visualization needs come together in one powerful, innovative solution.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Container>
                <div className="our-offering-content">
                    <div className="our-offering-floorplan">
                        <h3 style={{ textAlign: "center", marginBottom: '20px', width: "100%", margin: "auto" }}>Introducing Indepth Features of Realty Verse:</h3>
                        <h4 style={{ textAlign: "center", marginBottom: '20px', width: "100%", margin: "auto" }}>The Next Level in Architectural Visualization.</h4>
                        <Row style={{ alignItems: 'center', margin: '40px 0px 10px  0', overflow: 'hidden' }}>
                            <Col md={4} className="what-is-floor" style={{ textAlign: "center" }}
                                data-aos="fade-right"
                            >
                                <h3>What is a Floor</h3>
                                <h3>Plan design?</h3>
                            </Col>
                            <Col md={8} data-aos="fade-left">
                                <p>2D architectural drawing is perfect for (different spheres, such as) commercial spaces, office and house layouts, home design, and renovations. In the field of real estate marketing, it’s a great way for posting advertising and brochures of the property to customers without having to show them a sketch. Whether you're starting construction of a new house or home remodeling project, a 2D plan with dimensions is essential to create and manage your idea from start to finish.</p>
                            </Col>
                        </Row>
                        <video
                            ref={floorplanVideoRef}
                            autoPlay
                            loop
                            playsInline
                            preload="auto"
                            muted
                            style={{ borderRadius: "20px", objectFit: "cover" }} width="100%" height="500">
                            <source src="/assets/videos/floorplan.mp4" type="video/mp4" />
                            Your browser does not support HTML video.
                        </video>
                    </div>
                    <div className="transform">
                        <Row>
                            <Col md={6} data-aos="fade-up"
                                style={{ overflow: 'hidden' }}
                                data-aos-duration="1000">
                                <div className="transform-content">
                                    <h3 className="mb-3">The Power of Still Interior & Exterior Renders</h3>
                                    <p>Struggling to translate your architectural brilliance into captivating presentations? Static plans and models often fall short, leaving both you and potential buyers disconnected. So, we bridge that gap with stunning still interior & exterior renders. These hyper-realistic visuals aren't just beautiful; they're powerful communication tools. Forget tedious explanations – our renders showcase the project's architectural details, material finishes, and overall aesthetic with unparalleled clarity. Imagine instantly captivating investors and securing approvals with visuals that ignite excitement for your project. Streamline your workflow, enhance client communication, and accelerate sales cycles with still renders that showcase your vision in its full glory. </p>
                                </div>
                            </Col>
                            <Col md={6} data-aos="fade-down"
                                data-aos-easing="linear"
                                data-aos-duration="1500">
                                <div className="transform-image">
                                    <Carousel data-bs-theme="dark" pause={false}>
                                        <Carousel.Item interval={1000}>
                                            <img slot="first" src="/assets/images/comparison/Exterior.png" alt="Before" className="img-fluid" />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img slot="second" src="/assets/images/comparison/Interior.png" alt="After" className="img-fluid" />
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="interior-360" style={{ marginTop: "40px" }}>
                        <Row>
                            <Col md={6}>
                                <Row style={{ overflow: 'hidden' }}>
                                    <Col md={12} data-aos="fade-right">
                                        <video ref={exteriorVideoRef} preload="auto" autoPlay playsInline loop style={{ borderRadius: "20px" }} muted>
                                            <source src="/assets/images/cuverse/360.mp4" type="video/mp4" />
                                        </video>
                                    </Col>
                                    <Col md={12} data-aos="fade-up">
                                        <h3 style={{ margin: "20px 0 10px 0 " }}>Showcase Your Project in Stunning Detail, before a Single Brick is Laid with Cinematic Videos </h3>
                                        <p style={{ margin: "0 0 20px 0 ", textAlign: "justify" }}>
                                            In today's fast-paced real estate market, developers and architects understand the critical need for impactful marketing materials. While traditional presentations and static images have their place, they often fall short of capturing the full potential of a project. Imagine showcasing not just the architectural details of your development, but also the vibrant community, the surrounding environment, and the overall lifestyle it embodies. This is where cinematic video comes in, and at Cubedots, we're revolutionizing architectural marketing with our immersive cinematic video services.
                                        </p>
                                        <p style={{ margin: "0 0 20px 0", textAlign: "justify" }}>
                                            Traditional methods often neglect to showcase the project's surrounding environment and the sense of community it fosters. This can make it difficult to convey the true vibrancy and energy that comes with a new development. Hence, cinematic video is your secret weapon, a powerful element designed to address these pain points directly.
                                        </p>
                                        <p style={{ margin: "0 0 20px 0 ", textAlign: "justify" }}>
                                            So, extend the story beyond the building itself, showcasing the surrounding amenities, community, and lifestyle your vision offers. It fosters a deeper connection with potential buyers, allowing them to envision their future lives within the project's vibrant environment. This isn't just about showcasing features; it's about creating an experience that ignites excitement and accelerates your sales cycle.
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row style={{ overflow: 'hidden' }}>
                                    <Col md={12} data-aos="fade-left">
                                        <h3 style={{ margin: "0 0 10px 0 " }}>The Ultimate Walkthrough: Interactive 360° Videos for Your Projects </h3>
                                        <p style={{ margin: "0 0 20px 0 ", textAlign: "justify" }}>
                                            Imagine a world where architectural visualization transcends static images.  At Cubedots, we're shattering the limitations of traditional renders and propelling you into the future with our groundbreaking 360° interior video technology.  This isn't just about showcasing a space; it's about creating a transformative experience that allows potential buyers and investors to truly step inside your project.
                                        </p>
                                        <p style={{ margin: "0 0 20px 0", textAlign: "justify" }}>
                                            Forget flipping through flat renders that fail to capture the essence of your design.  With our 360° videos, viewers can embark on a captivating, interactive journey.  They'll be able to virtually navigate every meticulously crafted corner, experiencing the flow, scale, and design details with unparalleled realism.  Imagine them standing in the heart of your masterpiece, feeling the light streaming through meticulously placed windows, and envisioning their perfect life unfolding within its walls.
                                        </p>
                                        <p style={{ margin: "0 0 20px 0 ", textAlign: "justify" }}>
                                            Our 360° videos go beyond mere visuals; they foster an emotional connection that static images simply can't replicate.  Viewers can experience the ambience of the space, envision customized furniture placements, and truly immerse themselves in the project's potential.  This is a game-changer in the architectural visualization industry, offering you a powerful tool to distinguish your projects from the competition.
                                        </p>
                                        <p style={{ margin: "0 0 20px 0 ", textAlign: "justify" }}>
                                            Accelerate your sales cycle by captivating audiences with a truly immersive experience.  Our 360° videos are the key to securing a competitive edge in the global market.
                                        </p>
                                    </Col>
                                    <Col md={12} data-aos="fade-up">
                                        <video ref={video360Ref} preload="auto" autoPlay playsInline loop style={{ borderRadius: "20px" }} muted>
                                            <source src="/assets/images/cuverse/exterior.mp4" type="video/mp4" />
                                        </video>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="inventory-section">
                            <Row className="mt-5" style={{ alignItems: 'center' }}>
                                <Col md={6}>
                                    <video ref={video360Ref} preload="auto" autoPlay playsInline loop style={{ borderRadius: "20px" }} muted>
                                        <source src="/assets/images/cuverse/Live_Inventory.mp4" type="video/mp4" />
                                    </video>
                                </Col>
                                <Col md={6}>
                                    <h3 style={{ margin: "0 0 10px 0 " }}>See Only Available Homes: Your Real Estate Search Made Easy </h3>
                                    <p style={{ margin: "0 0 20px 0 ", textAlign: "justify" }}>
                                        Tired of scheduling tours for apartments that just vanished?  Welcome to the future of real estate searches! My listings boast "Live Inventory Update" technology, a game-changer that eliminates wasted time and ensures you only see available properties.
                                    </p>
                                    <p style={{ margin: "0 0 20px 0 ", textAlign: "justify" }}>
                                        Here's the deal: the moment a property goes under contract, it's automatically removed from my listings.  No more outdated information or wondering if that perfect place is still on the market.  "Live Inventory Update" puts you in control, allowing you to focus on properties that truly align with your needs and are demonstrably available.
                                        This real-time system streamlines your search, saving you precious time and frustration.
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default OurOffering;

import React from "react";
import BannerCarousel from "../../components/bannerCaraousel/component";
import "./OurOffer.scss"
import { useApp } from "../../hooks/AppHooks";
import { Col, Container, Row } from "react-bootstrap";

const ArchitectureVisulization: React.FC = () => {
    const { homeEnglishData } = useApp();
    const bannerImage = homeEnglishData?.ourOffering?.architecture?.bannerSection;

    return (
        <>
            <div className="architecture-visual">
                <BannerCarousel bannerImage={bannerImage} />
                <Container>
                    <h3 className="main-page-heading" style={{ textAlign: "center" }}>
                        <span>Architeture Visulization</span>
                    </h3>
                    <div className="first-section">
                        <Row>
                            <h3>Lorem ipsum dolor sit amet</h3>
                            <Col md={5}>
                                <img src="/assets/images/projects/collage/03_RustamJee.png" className="img-fluid" />
                            </Col>
                            <Col md={7}>
                                <Row>
                                    <Col md={12}>
                                        <p>Lorem ipsum dolor sit amet consectetur. Orci tortor sem pretium viverra sit. Ultricies viverra pharetra curabitur vitae suspendisse. Et cursus aenean vel massa. Dolor nibh quam in etiam quis volutpat volutpat.Lorem ipsum dolor sit amet consectetur. Orci tortor sem pretium viverra sit. Ultricies viverra pharetra curabitur vitae suspendisse. Et cursus aenean vel massa. Dolor nibh quam in etiam quis volutpat volutpat.Lorem ipsum dolor sit amet consectetur. Orci tortor sem pretium viverra sit. Ultricies viverra pharetra curabitur vitae suspendisse. Et cursus aenean vel massa. Dolor nibh quam in etiam quis volutpat volutpat.Lorem ipsum dolor sit amet consectetur. Orci tortor sem pretium viverra sit. Ultricies viverra pharetra curabitur vitae suspendisse. Et cursus aenean vel massa. Dolor nibh quam in etiam quis volutpat volutpat.amet consectetur. Orci tortor sem pretium viverra sit. Ultricies viverra pharetra curabitur vitae suspendisse.</p>
                                    </Col>
                                    <Col md={8}>
                                        <img src="/assets/images/projects/collage/01_PHNC.png" className="img-fluid" />
                                    </Col>
                                    <Col md={4}>
                                        <img src="/assets/images/01.jpg" className="img-fluid" />
                                    </Col>
                                    <Col md={12}>
                                        <img src="/assets/images/projects/collage/01_PHNC.png" className="img-fluid" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className="second-section">
                        <Row>
                            <Col md={4}>
                                <img src="/assets/images/projects/collage/03_RustamJee.png" className="img-fluid" />
                            </Col>
                            <Col md={7}>
                                <Row>
                                    <div className="second-sub-section">
                                        <h3>Lorem ipsum dolor sit amet</h3>
                                    </div>
                                    <Col md={12}>
                                        <p>consectetur. Orci tortor sem pretium viverra sit. Ultricies viverra pharetra curabitur vitae suspendisse. Et cursus aenean vel massa. Dolor nibh quam in etiam quis volutpat volutpat.Lorem ipsum dolor sit amet consectetur. Orci tortor sem pretium viverra sit. Ultricies viverra pharetra curabitur vitae suspendisse. Et cursus aenean vel massa. Dolor nibh quam in etiam quis volutpat volutpat.Lorem ipsum dolor sit amet consectetur. Orci tortor sem pretium viverra sit. Ultricies viverra pharetra curabitur vitae suspendisse. Et cu</p>
                                    </Col>
                                    <Col md={12}>
                                        <img src="/assets/images/projects/collage/01_PHNC.png" className="img-fluid" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className="third-section">
                        <Row>
                            <Col md={6}>
                                <h3>Lorem ipsum dolor sit amet</h3>
                                <p>Lorem ipsum dolor sit amet consectetur. Consectetur leo tortor massa diam dignissim blandit eget pulvinar. Tellus tempor feugiat turpis ultricies. A netus viverra libero dapibus integer posuere id. Auctor amet ut et hendrerit accumsan erat vitae.Lorem ipsum dolor sit amet consectetur. Consectetur leo tortor massa diam dignissim blandit eget pulvinar. Tellus tempor feugiat turpis ultricies. A netus viverra libero dapibus integer posuere id. </p>
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <Col md={6}>
                                        <img src="/assets/images/projects/collage/03_RustamJee.png" className="img-fluid" />
                                    </Col>
                                    <Col md={6}>
                                        <img src="/assets/images/projects/collage/03_RustamJee.png" className="img-fluid" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className="fourth-section">
                        <Row>
                            <Col md={6}>
                                <img src="/assets/images/01.jpg" className="img-fluid" />
                            </Col>
                            <Col md={6}>
                                <h3>Lorem ipsum dolor sit amet consectetur.</h3>
                                <p>Lorem ipsum dolor sit amet consectetur. Consectetur leo tortor massa diam dignissim blandit eget pulvinar. Tellus tempor feugiat turpis ultricies. A netus viverra libero dapibus integer posuere id. Auctor amet ut et hendrerit accumsan erat vitae.Lorem ipsum dolor sit amet consectetur. Consectetur leo tortor massa diam dignissim blandit eget pulvinar. Tellus tempor feugiat turpis ultricies. A netus viverra libero dapibus integer posuere id. </p>
                            </Col>
                        </Row>
                    </div>
                    <div className="fifth-section">
                        <Row>
                            <Col md={12}>
                                <h3>Lorem ipsum dolor sit amet consectetur.</h3>
                                <p>Lorem ipsum dolor sit amet consectetur. Consectetur leo tortor massa diam dignissim blandit eget pulvinar. Tellus tempor feugiat turpis ultricies. A netus viverra libero dapibus integer posuere id. Auctor amet ut et hendrerit accumsan erat vitae. Venenatis faucibus sed id est vitae placerat aenean. Netus tortor mattis sit feugiat in nulla porta neque tristique. Vestibulum tortor morbi interdum at. Enim sed diam blandit nunc arcu dictum posuere. Lorem habitasse libero elit pulvinar cras placerat. Tellus enim tincidunt arcu </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <img src="/assets/images/projects/collage/03_RustamJee.png" className="img-fluid" />
                            </Col>
                            <Col md={3}>
                                <img src="/assets/images/projects/collage/03_RustamJee.png" className="img-fluid" />
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <Col md={12}>
                                        <img src="/assets/images/projects/collage/01_PHNC.png" className="img-fluid" />
                                    </Col>
                                    <Col md={12}>
                                        <img src="/assets/images/projects/collage/01_PHNC.png" className="img-fluid" />
                                    </Col>
                                    <Col md={12}>
                                        <img src="/assets/images/projects/collage/01_PHNC.png" className="img-fluid" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}
export default ArchitectureVisulization;
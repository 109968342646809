import React from "react";
import { Col, Row } from "react-bootstrap";
import "../Developers.scss"

interface WhytrustUsProps {
    developerData: any;
}

const WhyTurstUsComponent: React.FC<WhytrustUsProps> = ({ developerData }) => {
    return (
        <>
            <div className="why-trust-section">
                <h3>{developerData?.projectlist?.whytrustus?.turstheading}</h3>
                {developerData?.projectlist?.whytrustus?.trustuslist?.map((item: any, index: any) => {
                    return (
                        <>
                            <div className="why-trust-content" key={index}>
                                {
                                    index % 2 == 0 ?
                                        <Row style={{ alignItems: "center" }}>
                                            <Col md={6}>
                                                <h3>{item?.title}</h3>
                                                <p>{item?.description}</p>
                                            </Col>
                                            <Col md={6}>
                                                <img src={item?.image} className="img-fluid" />
                                            </Col>
                                        </Row>
                                        :
                                        <Row style={{ alignItems: "center" }}>
                                            <Col md={6}>
                                                <img src={item?.image} className="img-fluid" />
                                            </Col>
                                            <Col md={6}>
                                                <h3>{item?.title}</h3>
                                                <p>{item?.description}</p>
                                            </Col>
                                        </Row>
                                }
                            </div>
                        </>
                    )
                })
                }
            </div >
        </>
    )
}
export default WhyTurstUsComponent;
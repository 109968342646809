import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { SelectChangeEvent } from '@mui/material/Select';

interface HomeDataType {
    homeEnglishData?: any;
    loading?: boolean;
    handleChange?: any;
    lang?: string
}

const useContextAppValue = () => {
    const [homeEnglishData, setHomeEnglishData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [lang, setLang] = React.useState("en");

    const handleChange = async (event: SelectChangeEvent) => {
        const selectedLang = event.target.value as string;
        setLang(selectedLang);
        setLoading(true);
        try {
            const response = await axios.get(`./assets/data/${selectedLang}.json`);
            setHomeEnglishData(response.data);
            setError(null);
        } catch (error) {
            setError('error.message');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get("./assets/data/en.json");
                setHomeEnglishData(response.data);
                setError(null);
            } catch (error) {
                setError('error.message');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return {
        homeEnglishData,
        loading,
        error,
        handleChange,
        lang
    };
};

export const AppContext = createContext<HomeDataType>({});

interface AppProviderProps {
    children?: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    const value = useContextAppValue();
    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
}
import React, { useEffect, useState } from "react";
import { useApp } from "../hooks/AppHooks";
import BannerCarousel from "../components/bannerCaraousel/component";
import ContactUsComponent from "../components/contactUs/component";
import { Col, Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AOS from "aos";
import "aos/dist/aos.css";
import { INVENTORY_2D_API } from "../auth/constant";

interface CountryCode {
    name: string;
    dial_code: string;
    code: string;
}

const ContactPage: React.FC = () => {
    const { homeEnglishData } = useApp();
    const bannerImage = homeEnglishData?.contactus?.bannerSection;
    const contactData = homeEnglishData?.contactus;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('+91');
    const [mobNumber, setMobNumber] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<CountryCode[]>([]);
    const [error, setError] = useState<string | null>(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        const formData = {
            firstName,
            lastName,
            email,
            mobNumber,
            message,
            code
        };

        try {
            const response = await axios.post(INVENTORY_2D_API + 'user/postQuery', formData);
            console.log('Form submitted successfully!', response.data);
            setFirstName('');
            setLastName('');
            setEmail('');
            setMobNumber('');
            setMessage('');
            setCode('+91');
            setShow(true);
            setLoading(false);
        } catch (error) {
            console.error('Error submitting form:', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/assets/data/countryCode.json');
                console.log(response)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                console.log(jsonData)
                if (jsonData) {
                    setData(jsonData.countries);
                } else {
                    throw new Error('Fetched data is not an array');
                }
                setLoading(false);
            } catch (error: any) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <>
            <div className="conatact-page">
                <Row style={{ padding: "0" }}>
                    <Col md={6} style={{ padding: "0" }}>
                        <BannerCarousel bannerImage={bannerImage} />
                    </Col>
                    <Col md={6} style={{ padding: "0", background: "#1E2430" }}>
                        <div className="contact-form-parent" style={{ padding: "0", background: "#1E2430", height: "100%" }}>
                            <Form onSubmit={onSubmitHandler}
                                className="contact-form"
                            >
                                <h4 data-aos={window.innerWidth < 992 ? "" : "fade-down"} style={{ color: "#f2f5f7", overflow: 'hidden' }}>Contact Us</h4>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Group className="mb-3" controlId="firstName">
                                            {/* <Form.Label>First Name</Form.Label> */}
                                            <Form.Control
                                                data-aos={window.innerWidth < 992 ? "" : "fade-right"}
                                                type="text"
                                                placeholder="First name"
                                                value={firstName}
                                                required
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-3" controlId="lastName">
                                            {/* <Form.Label>Last Name</Form.Label> */}
                                            <Form.Control
                                                data-aos={window.innerWidth < 992 ? "" : "fade-left"}
                                                type="text"
                                                placeholder="Last Name"
                                                value={lastName}
                                                required
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <Row>
                                            <Col xs={4} className="pe-0">
                                                <Form.Group className="mb-3" controlId="code">
                                                    <Form.Select aria-label="Default select example"
                                                        data-aos={window.innerWidth < 992 ? "" : "zoom-in"}
                                                        onChange={(e) => setCode(e.target.value)}
                                                        required
                                                    >
                                                        {data.map((country) => (
                                                            <option key={country.code}>
                                                                {country.code} ({country.name})
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={8}>
                                                <Form.Group className="mb-3" controlId="mobNumber">
                                                    {/* <Form.Label>Mobile Number</Form.Label> */}
                                                    <Form.Control
                                                        data-aos={window.innerWidth < 992 ? "" : "zoom-in"}
                                                        type="text"
                                                        placeholder="Mobile Number"
                                                        value={mobNumber}
                                                        required
                                                        onChange={(e) => setMobNumber(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-3" controlId="email">
                                            {/* <Form.Label>Email address</Form.Label> */}
                                            <Form.Control
                                                data-aos={window.innerWidth < 992 ? "" : "zoom-in"}
                                                type="email"
                                                placeholder="email"
                                                value={email}
                                                required
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    {/* <Form.Label>Message</Form.Label> */}
                                    <Form.Control as="textarea" rows={3}
                                        data-aos={window.innerWidth < 992 ? "" : "fade-up"}
                                        data-aos-anchor-placement="top-bottom"
                                        placeholder="message"
                                        required
                                        value={message}
                                        onChange={(e) => { setMessage(e.target.value) }}
                                    />
                                </Form.Group>
                                <Row style={{ width: '50%', marginLeft: "0" }}>
                                    {
                                        !loading ?
                                            <Button variant="secondary" type="submit"
                                                data-aos={window.innerWidth < 992 ? "" : "flip-left"}
                                                data-aos-easing="ease-out-cubic"
                                                data-aos-duration="2000"
                                                style={{ backgroundColor: "#08428D", borderColor: "transparent" }}>
                                                Submit Now
                                            </Button>
                                            :
                                            <Button variant="secondary" type="submit"
                                                data-aos="flip-left"
                                                data-aos-easing="ease-out-cubic"
                                                data-aos-duration="2000"
                                                style={{ backgroundColor: "#08428D", borderColor: "transparent" }}>
                                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span> Submit Now
                                            </Button>

                                    }

                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Container>
                    <ContactUsComponent contactData={contactData} />
                </Container>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body style={{ color: "#08428D" }}>Your Request Submitted Successfully! Thankyou For Contact.</Modal.Body>
                </Modal>
            </div>
        </>
    )
}
export default ContactPage;